import React from 'react';
import { connect } from 'react-redux';
import { withContainerWrapper } from '../ContainerWrapper';
import actions from '../../actions/catalogue/filter';
import { fetchValidatedAnalysisTypes } from '../../actions/settings/scorecards';
import { addItemCountAndValidatingInfoToResources } from '../../selectors/resources/analysesResourcesSelector';
import FilterAnalyses from '../../components/header/FilterAnalyses';
import { BRAND_AREA } from '../../constants/InAppAreaConst';
import { FilterType, FilterTypeGo } from '../../../types/filter';
import { Analysis, StandardResource } from '../../../types/resources';
import { AsyncDispatch } from '../../../types/global';
import { ApplicationState } from '../../reducers';

type AnalysisFilterContainerProps = {
  dispatch: AsyncDispatch;
  inAppArea: string;
  filter: FilterTypeGo;
  filterOld: FilterType;
  analysisResources: Analysis;
  fetchingAnalysesAlertItemCount: boolean;
  brandId: number;
  addFilterParameterValue: (params: {
    sectionKey: string;
    referenceId: number;
    resourceId: number | number[];
    resourceName?: string;
  }) => void;
  removeFilterParameterValue: (params: {
    sectionKey: string;
    referenceId: number;
    value: any;
  }) => void;
};

type AnalysisFilterContainerState = {
  selectedSegmentId?: number;
  selectedAlertTypeId?: number;
  selectedScorecardId?: number;
  selectedAlertIds?: number[];
};

class AnalysisFilterContainer extends React.Component<
  AnalysisFilterContainerProps,
  AnalysisFilterContainerState
> {
  constructor(props: AnalysisFilterContainerProps) {
    super(props);
    this.state = {
      selectedSegmentId: undefined,
      selectedAlertTypeId: undefined,
      selectedScorecardId: undefined,
      selectedAlertIds: undefined,
    };
  }

  componentDidMount() {
    const { filterOld } = this.props;

    if (this.props.brandId) {
      this.props.dispatch(actions.fetchAnalysesAlertItemCount(this.props.brandId));
      this.props.dispatch(fetchValidatedAnalysisTypes(this.props.brandId));
    }

    if (filterOld && filterOld.id && !filterOld.filter_analysis) {
      this.createAnalysesFilter();
    }
    if (this.props.filter && this.props.filter.filter_analysis) {
      this.setState({ selectedAlertIds: this.getSelectedAlertIds() });
    }
  }

  componentDidUpdate(prevProps: AnalysisFilterContainerProps) {
    const { filter, filterOld } = this.props;
    if (filter) {
      if (prevProps.filter !== filter && filter.filter_analysis) {
        this.setState({ selectedAlertIds: this.getSelectedAlertIds() });
      }
      if (
        filterOld &&
        prevProps.filterOld !== filterOld &&
        !filterOld.filter_analysis &&
        // @ts-ignore
        !filterOld.delete
      ) {
        this.createAnalysesFilter();
      }
    }
  }

  createAnalysesFilter = () => {
    const { filter } = this.props;
    this.props.dispatch(actions.createAnalysisFilter(filter.id));
  };

  selectSegment = (id: number) => {
    const { selectedSegmentId } = this.state;
    this.setState({ selectedSegmentId: id === selectedSegmentId ? undefined : id });
  };

  selectAlertType = (id: number) => {
    const { selectedAlertTypeId } = this.state;
    this.setState({ selectedAlertTypeId: id === selectedAlertTypeId ? undefined : id });
  };

  selectScorecard = (id: number) => {
    const { selectedScorecardId } = this.state;
    this.setState({ selectedScorecardId: id === selectedScorecardId ? undefined : id });
  };

  getAnalysisFilter = (filter = this.props.filter) => {
    if (filter) {
      if (filter.filter_analysis) {
        return filter.filter_analysis[0];
      }
    }
  };

  getSelectedAlertIds = () => {
    const analysisFilter = this.getAnalysisFilter();

    const alertParameter = analysisFilter?.parameters.find(p => p.reference_id === 3);
    if (alertParameter) {
      if (alertParameter.values.length > 0) {
        return alertParameter.values.map(value => value.analysis_id);
      }
    }
  };

  valueIsSelected = (resourceId: number, typeId: number) => {
    const analysisFilter = this.getAnalysisFilter();
    const parameter = analysisFilter?.parameters.find(p => p.reference_id === resourceId);

    if (parameter) {
      return !!parameter.values.find(value => value.analysis_id === typeId);
    }

    return false;
  };

  filterAlerts = () => {
    const { analysisResources } = this.props;
    const { selectedSegmentId, selectedAlertTypeId, selectedScorecardId } = this.state;
    let alerts = analysisResources.analysis_types;
    if (selectedSegmentId) alerts = alerts.filter(alert => alert.segment_id === selectedSegmentId);
    if (selectedAlertTypeId)
      alerts = alerts.filter(alert => alert.alert_type_id === selectedAlertTypeId);
    if (selectedScorecardId) {
      const selectedScorecard = analysisResources.scorecards.find(
        scorecard => scorecard.id === selectedScorecardId
      );
      const scorecardTypeIds = selectedScorecard ? selectedScorecard.type_ids.split(',') : [];
      alerts = alerts.filter(alert => scorecardTypeIds?.includes(alert.id.toString()));
    }

    return alerts;
  };

  addSegmentFilter = (segmentId: number) => {
    const resourceId = 1;
    this.addValue(resourceId, segmentId);
  };

  addTypeFilter = (alertTypeId: number) => {
    const resourceId = 2;
    this.addValue(resourceId, alertTypeId);
  };

  addScorecardFilter = (scorecardId: number) => {
    const resourceId = 4;
    this.addValue(resourceId, scorecardId);
  };

  addAlertFilter = (typeId: number) => {
    const resourceId = 3;
    if (this.valueIsSelected(resourceId, typeId)) {
      this.props.removeFilterParameterValue({
        sectionKey: 'filter_analysis',
        referenceId: resourceId,
        value: typeId,
      });
    } else {
      this.addValue(resourceId, typeId);
    }
  };

  getAnalysisName = (referenceId: number, typeId: number) => {
    const { analysisResources } = this.props;
    const reference = analysisResources.references.find(reference => reference.id === referenceId);
    // @ts-ignore
    const types = analysisResources[reference.name];
    const type = types.find((type: StandardResource) => type.id === typeId);
    return type.name;
  };

  addValue = (referenceId: number, typeId: number) => {
    // check if filter has already a parameter with referenceId
    if (this.valueIsSelected(referenceId, typeId)) return;
    const valueName = this.getAnalysisName(referenceId, typeId);

    this.props.addFilterParameterValue({
      sectionKey: 'filter_analysis',
      referenceId,
      resourceId: typeId,
      resourceName: valueName,
    });
  };

  render() {
    if (this.props.analysisResources) {
      return (
        <FilterAnalyses
          inAppArea={this.props.inAppArea}
          fetchingAnalysesAlertItemCount={this.props.fetchingAnalysesAlertItemCount}
          segments={this.props.analysisResources.segments}
          alertTypes={this.props.analysisResources.alert_types}
          scorecards={this.props.analysisResources.scorecards}
          analysisTypes={this.filterAlerts()}
          selectedSegmentId={this.state.selectedSegmentId}
          selectedAlertTypeId={this.state.selectedAlertTypeId}
          selectedScorecardId={this.state.selectedScorecardId}
          selectedAlertIds={this.state.selectedAlertIds || []}
          addSegmentFilter={this.addSegmentFilter}
          addTypeFilter={this.addTypeFilter}
          addScorecardFilter={this.addScorecardFilter}
          addAlertFilter={this.addAlertFilter}
          selectSegment={this.selectSegment}
          selectAlertType={this.selectAlertType}
          selectScorecard={this.selectScorecard}
        />
      );
    }

    return null;
  }
}

function mapStateToProps(state: ApplicationState) {
  const { inAppArea } = state.app.global;
  const isBrandArea = inAppArea === BRAND_AREA;
  return {
    inAppArea: state.app.global.inAppArea,
    analysisResources: isBrandArea
      ? addItemCountAndValidatingInfoToResources(state)
      : state.resources.analysis,
    fetchingAnalysesAlertItemCount: state.catalogue.filter.fetchingAnalysesAlertItemCount,
    brandId: state.parent.brands.selectedBrandId,
  };
}

export { AnalysisFilterContainer };
export default connect(mapStateToProps)(withContainerWrapper(AnalysisFilterContainer));
