import { Button } from 'antd';
import React from 'react';

import CatalogueAddProductDrawer from './CatalogueAddProductDrawer';
import catalogueTranslation from '../../../constants/CatalogueTranslations.json';
import CatalogueBarFilter from './CatalogueBarFilter.tsx';

class CatalogueBar extends React.Component {
  state = {
    showAddProductDrawer: false,
  };

  handleAddProductDrawer = () => {
    this.setState(prevProps => ({
      showAddProductDrawer: !prevProps.showAddProductDrawer,
    }));
  };

  render() {
    return (
      <React.Fragment>
        <div className="catalogue-bar">
          {this.props.isManufacturer && (
            <div className="add-button-container">
              <Button
                className="ant-btn-green add-button"
                onClick={this.handleAddProductDrawer}
                size="small"
              >
                {catalogueTranslation.catalogueBar.newProduct}
              </Button>
            </div>
          )}
          {this.props.selectedFilterGo.id && (
            <CatalogueBarFilter
              segment="brand_catalogue"
              availableReceivers={this.props.availableReceivers}
              availableChannels={this.props.availableChannels}
              subBrands={this.props.subBrands}
              resources={this.props.resources}
              selectedFilterGo={this.props.selectedFilterGo}
              filters={this.props.filters}
              goOnly={this.props.goOnly}
              disableKeywordInput={this.props.disableKeywordInput}
              onFilterChange={this.props.fetchFilter}
              handleFilterByKeywords={this.props.handleFilterByKeywords}
              brandItemTags={this.props.brandItemTags}
              handleOpenFilter={this.props.handleOpenFilter}
              handleSaveNewFilter={this.props.handleSaveNewFilter}
              handleAddTag={this.props.handleAddTag}
              handleRemoveTag={this.props.handleRemoveTag}
              handleDeleteFilterParameter={this.props.handleDeleteFilterParameter}
              brandCustomFields={this.props.brandCustomFields}
              canManageGlobalFilter={this.props.canManageGlobalFilter}
              readOnlyFilter={this.props.readOnlyFilter}
            />
          )}
        </div>

        {this.state.showAddProductDrawer && (
          <CatalogueAddProductDrawer
            subBrands={this.props.subBrands}
            visible={this.state.showAddProductDrawer}
            onClose={this.handleAddProductDrawer}
            handleAddNewProduct={this.props.handleAddNewProduct}
          />
        )}
      </React.Fragment>
    );
  }
}

export default CatalogueBar;
