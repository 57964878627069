import React from 'react';
import { Form, Select, Tag } from 'antd';
import { Field, FieldProps } from 'formik';
import { get as _get } from 'lodash';
import { SelectProps } from 'antd/lib/select';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import AntTooltip from '../AntTooltip';

type FormFilterMultiSelectProps = {
  name: string;
  values: { id: number; name: string; icon?: React.ReactNode }[];
  label?: string | React.ReactNode;
  required?: boolean;
  showTagTooltip?: boolean;
  getTagTooltip?: (id: number) => string;
  loading?: boolean;
  className?: string;
} & SelectProps<any>;

const FormFilterMultiSelect: React.FC<FormFilterMultiSelectProps> = ({
  name,
  className,
  values,
  label,
  placeholder,
  required,
  disabled,
  loading,
  getTagTooltip,
  showTagTooltip,
  ...selectProps
}) => {
  const tagRender = (props: CustomTagProps) => {
    const { label, closable, value, onClose } = props;
    const tooltip = getTagTooltip && getTagTooltip(value);

    if (loading) return <span />;

    return (
      <Tag closable={closable} onClose={onClose}>
        <AntTooltip title={tooltip} hide={!tooltip}>
          {label}
        </AntTooltip>
      </Tag>
    );
  };

  const fieldContent = ({ field, form }: FieldProps) => {
    const { errors, touched, setFieldValue, setFieldTouched } = form;
    const showValidationInfo = !!(_get(errors, name) && _get(touched, name));
    return (
      <Form.Item
        className={className}
        label={label}
        required={required}
        hasFeedback
        validateStatus={showValidationInfo ? 'error' : ''}
        help={(showValidationInfo && _get(errors, name)) || undefined}
      >
        <Select
          mode="multiple"
          placeholder={placeholder}
          value={field.value}
          onChange={(value: string[]) => {
            setFieldValue(name, value);
            setFieldTouched(name);
          }}
          disabled={disabled}
          loading={loading}
          tagRender={showTagTooltip ? tagRender : undefined}
          {...selectProps}
        >
          {values.map(value => (
            <Select.Option key={value.id} value={value.id}>
              <span>
                {value.icon && <span>{value.icon}</span>}
                {value.name}
              </span>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  };

  return <Field name={name}>{fieldContent}</Field>;
};

export default FormFilterMultiSelect;
