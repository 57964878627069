import { createSelector } from 'reselect';
import {
  ALL_FILTER_TYPE_ID,
  MARKED_FILTER_TYPE_ID,
  TEMP_FILTER_TYPE_ID,
  CUSTOM_FILTER_TYPE_ID,
  APPLICATION_LISTING_FILTER_TYPE_ID,
  ITEM_APPLICATION_LISTING_FILTER_TYPE_ID,
} from '../../constants/FilterConstants';

const getFilters = state => state.catalogue.filter.filters;
const getCurrentUser = state => state.user.user;
const getUserList = state => state.user.userNameList;

export const getFiltersWithUser = createSelector(
  [getFilters, getCurrentUser, getUserList],
  (filters, currentUser, allUsers) =>
    filters.map(filter => {
      const user = allUsers.find(f => f.id === filter.user_id);
      const userDetails = user ? user.email : '';
      return {
        ...filter,
        userEmail: currentUser.id === filter.user_id ? currentUser.email : userDetails,
      };
    })
);

export const getAllProductsFilter = createSelector([getFilters], filters =>
  filters.find(filter => filter.type_id === ALL_FILTER_TYPE_ID)
);

export const getMarkedProductsFilter = createSelector([getFilters], filters =>
  filters.find(filter => filter.type_id === MARKED_FILTER_TYPE_ID)
);

export const getTempProductsFilter = createSelector([getFilters], filters =>
  filters.find(filter => filter.type_id === TEMP_FILTER_TYPE_ID)
);

export const getApplicationListFilter = createSelector([getFilters], filters =>
  filters.find(filter => filter.type_id === APPLICATION_LISTING_FILTER_TYPE_ID)
);

export const getItemApplicationListFilter = createSelector([getFilters], filters =>
  filters.find(filter => filter.type_id === ITEM_APPLICATION_LISTING_FILTER_TYPE_ID)
);

export const getAllCustomFilters = createSelector([getFiltersWithUser], (filters = []) =>
  filters.filter(filter => filter.type_id === CUSTOM_FILTER_TYPE_ID)
);
