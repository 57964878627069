import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileSaver from 'file-saver';
import { ApplicationState } from '../../reducers';
import {
  fetchImport,
  fetchImportLogDetailsJSON,
  fetchImportLogs,
  fetchImportLogsDetailsExcel,
} from '../../actions/brand/import_export/fetch';
import ImportExportLogs from '../../components/body/import_export/ImportExportLogs';
import { importLogMapper } from '../../selectors/importExportLogsSelector';
import { getSelectedBrandCode } from '../../selectors/brand/brandSelector';
import { AsyncDispatch } from '../../../types/global';
import { isManufacturer } from '../../utils/UserUtils';
import { intercomEvent } from '../../utils/IntercomUtils';
import { fetchExportOptions } from '../../actions/brand/export/fetch';
import { openImportNotification } from '../../actions/importer';
import { getPageLimit } from '../../utils/Utils';

const ImportLogsContainer: React.FC = () => {
  const dispatch: AsyncDispatch = useDispatch();

  const {
    brandId,
    importLogs,
    fetchingImportLogs,
    fetchingImportErrors,
    importErrorLogDetail,
    user,
    userNameList,
    brandCode,
    importSources,
  } = useSelector((state: ApplicationState) => ({
    brandId: state.parent.brands.selectedBrandId,
    importLogs: importLogMapper(state),
    fetchingImportLogs: state.brand.importExport.fetchingImportLogs,
    fetchingImportErrors: state.brand.importExport.fetchingImportErrors,
    importErrorLogDetail: state.brand.importExport.importErrorLogDetail,
    user: state.user.user,
    userNameList: state.user.userNameList,
    brandCode: getSelectedBrandCode(state),
    importSources: state.resources.data.global.import_sources,
  }));

  const manufacturer = isManufacturer(user!);

  React.useEffect(() => {
    intercomEvent('viewed-import-export', {
      location: 'viewed-import-reports',
      brand_code: brandCode!,
    });
  }, [brandCode]);

  const [pageSize] = React.useState<number>(getPageLimit());
  const [selectedTypeFilterIds, setSelectedTypeFilterIds] = React.useState<number[]>([]);

  React.useEffect(() => {
    if (manufacturer) dispatch(fetchExportOptions('export', brandId));
  }, [brandId, dispatch, manufacturer]);

  React.useEffect(() => {
    dispatch(fetchImportLogs({ brandId, limit: pageSize }));
  }, [brandId, dispatch, pageSize]);

  const saveImportLogsDetailsFile = ({ errorLogFile }: { errorLogFile: any }) => {
    const blob = new Blob([errorLogFile], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    FileSaver.saveAs(blob, 'paramount_import_errors.xlsx');
  };

  const handleErrorDownload = (logId: number) =>
    dispatch(fetchImportLogsDetailsExcel(logId)).then(response =>
      saveImportLogsDetailsFile({ errorLogFile: response.action.payload.data })
    );

  const handleImportFileDownload = (logId: number) =>
    // @ts-ignore
    dispatch(fetchImport(logId));

  const handleFetchImportLogDetailsJSON = (logId: number) =>
    dispatch(fetchImportLogDetailsJSON(logId));

  const handleOpenNotification = (logId: number) => dispatch(openImportNotification(logId));

  const handleSourceFilter = (sourceIds: number[]) => {
    if (JSON.stringify(sourceIds) !== JSON.stringify(selectedTypeFilterIds))
      dispatch(
        fetchImportLogs({
          brandId,
          sourceIds,
          limit: pageSize,
        })
      );
    setSelectedTypeFilterIds(sourceIds);
  };

  const getImportLogs = (page: number) => {
    return dispatch(
      fetchImportLogs({
        brandId,
        sourceIds: selectedTypeFilterIds,
        limit: pageSize,
        page,
      })
    );
  };

  return (
    <ImportExportLogs
      logs={importLogs}
      fetchingLogs={fetchingImportLogs}
      fetchingErrors={fetchingImportErrors}
      errorLogDetails={importErrorLogDetail}
      handleErrorDownload={handleErrorDownload}
      handleFileDownload={handleImportFileDownload}
      handleFetchLogDetailsJSON={handleFetchImportLogDetailsJSON}
      handleOpenNotification={handleOpenNotification}
      fileTypeValues={importSources}
      pageSize={pageSize}
      selectedTypeFilterIds={selectedTypeFilterIds}
      handleFilterByType={handleSourceFilter}
      fetchLogs={getImportLogs}
    />
  );
};

export default ImportLogsContainer;
