import React from 'react';
import { Form, Select, Spin, Empty } from 'antd';
import { Field, FieldProps } from 'formik';
import { get as _get } from 'lodash';
import { SelectProps } from 'antd/lib/select';

type FormFilterSelectProps = {
  optionToolTip?: boolean;
  name: string;
  className?: string;
  values: {
    id: string | number | undefined;
    name: string | number;
    icon?: React.ReactNode;
  }[];
  label?: React.ReactNode;
  required?: boolean;
  noStyle?: boolean;
} & SelectProps<any>;

const FormFilterSelect: React.FC<FormFilterSelectProps> = props => {
  const { name, className, values, label, required, noStyle, ...selectProps } = props;

  const fieldContent = ({ field, form }: FieldProps) => {
    const { errors, touched, setFieldValue, setFieldTouched } = form;
    const showValidationInfo = !!(_get(errors, name) && _get(touched, name));
    return (
      <Form.Item
        className={className}
        label={label}
        required={required}
        noStyle={noStyle}
        hasFeedback
        validateStatus={showValidationInfo ? 'error' : ''}
        help={(showValidationInfo && _get(errors, name)) || undefined}
      >
        <Select
          value={field.value || (field.value === null ? undefined : field.value)}
          onChange={value => setFieldValue(name, value === undefined ? null : value)}
          onBlur={() => {
            setFieldTouched(name);
          }}
          getPopupContainer={trigger => trigger.parentNode}
          notFoundContent={
            props.loading ? <Spin /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          }
          {...selectProps}
        >
          {values.map(value => (
            <Select.Option key={value.id} value={value.id}>
              <span>
                {value.icon && <span style={{ marginRight: 2 }}>{value.icon}</span>}
                {value.name}
              </span>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  };

  return <Field name={name}>{fieldContent}</Field>;
};

export default FormFilterSelect;
