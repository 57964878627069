import React from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { withContainerWrapper } from '../ContainerWrapper';
import PartTypeFilterContainer from './PartTypeFilterContainer';
import ApplicationFilterContainer from './ApplicationFilterContainer';
import AnalysisFilterContainer from './AnalysisFilterContainer';
import CustomFilterContainer from './CustomFilterContainer';
import FilterBar from '../../components/header/FilterBar';
import actions from '../../actions/catalogue/filter';
import { filterNavigationBarElements } from '../../constants/ParamountReactConstants';
import { COUNTRY_CODES } from '../../constants/ExtendedInfoConstants';
import hotjarConstants from '../../constants/Hotjar.json';
import constants from '../../constants/HeaderTranslation.json';
import * as utils from '../../utils/Utils';
import { isReceiver } from '../../utils/UserUtils.ts';
import { runningOnGoOnlyApi } from '../../utils/Permissions';
import { FilterCustomReference } from '../../../types/filter.ts';
import { getFiltersWithUser } from '../../selectors/catalogue/filterSelector';
import { CUSTOM_FILTER_TYPE_ID } from '../../constants/FilterConstants';

const { triggers: hotjarTriggers } = hotjarConstants;

class FilterContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFilterTab: 0,
      goOnly: runningOnGoOnlyApi(props.user),
    };
  }

  isActive = key => (this.state.activeFilterTab === key ? 'active' : '');

  handleFilterTabs = (key = null) => {
    this.setState({ activeFilterTab: key });
    if (key === 0) utils.triggerHj(hotjarTriggers.filter_part_type);
    else if (key === 1) utils.triggerHj(hotjarTriggers.filter_application);
    else if (key === 2) utils.triggerHj(hotjarTriggers.filter_analysis);
  };

  getCustomFieldName = id => {
    const customField = this.props.brandCustomFields.find(customField => customField.id === id);
    // custom field can be deleted but stays in filter
    return customField ? customField.name : '';
  };

  getExpi = id => {
    const { resources } = this.props;
    const groups = resources.extended_information.groups;
    const allExpi = groups.map(group => group.types).reduce((a, b) => a.concat(b), []);
    return allExpi.find(expi => expi.id === id);
  };

  getExpiName = id => {
    const expi = this.getExpi(id);
    return expi.name;
  };

  getExpiValueName = (id, code) => {
    const { resources } = this.props;
    const expi = this.getExpi(id);
    if (expi.format === 'ARRAY' || expi.format === 'BOOL') {
      const value = COUNTRY_CODES.includes(expi.code)
        ? resources.global.countries.find(opt => opt.code === code)
        : expi.options.find(opt => opt.code === code);
      return value.name === value.code ? value.name : `${value.code} - ${value.name}`;
    }
    return code;
  };

  getSubBrandName = id => {
    const { subBrands, receiverSubBrands, user } = this.props;
    const receiverUser = isReceiver(user);

    const brandList = receiverUser ? receiverSubBrands : subBrands;
    const subBrand = brandList.find(brand => brand.id === id);

    return (subBrand && subBrand.name) || id;
  };

  parameterIsBool = parameter => {
    const { resources, brandCustomFields } = this.props;
    if (
      [
        FilterCustomReference.UNIVERSAL_PART,
        FilterCustomReference.SUBMITTED_CHANNEL,
        FilterCustomReference.SUBMITTED_RECEIVER,
        FilterCustomReference.ONLINE_CHANNEL,
        FilterCustomReference.ONLINE_RECEIVER,
      ].includes(parameter.filter_custom_reference_id)
    )
      return true;
    if (parameter.filter_custom_reference_id === FilterCustomReference.CUSTOM_FIELDS) {
      const customField = brandCustomFields.find(field => field.id === parameter.reference_id);
      return customField ? customField.custom_field_type_id === 4 : false;
    }
    if (parameter.filter_custom_reference_id === FilterCustomReference.EXTENDED_INFORMATIONS) {
      const groups = resources.extended_information.groups;
      const allExpi = groups.map(group => group.types).reduce((a, b) => a.concat(b), []);
      const expi = allExpi.find(expi => expi.id === parameter.reference_id);
      return expi.format === 'BOOL';
    }
    return false;
  };

  getSectionFilter = (filter, sectionKey) => {
    if (filter[sectionKey]) return Object.values(filter[sectionKey])[0];
  };

  getSectionFilterId = sectionKey => {
    const filter = this.props.filter;
    if (filter[sectionKey]) return Object.keys(filter[sectionKey])[0];
  };

  getValueId = (parameter, value) => {
    const valueId = Object.keys(parameter.values).find(id => {
      const valueObj = parameter.values[id];
      return (
        value === valueObj.category_name ||
        value === valueObj.resource_name ||
        value === valueObj.resource_id ||
        value === valueObj.analysis_id ||
        value === valueObj.value
      );
    });

    return valueId;
  };

  getFilterParameterId = ({
    sectionKey,
    resource,
    level,
    referenceId,
    filterCustomReferenceId,
  }) => {
    const { filter } = this.props;
    let filterParameterId;
    Object.values(filter[sectionKey]).forEach(block => {
      Object.keys(block.parameters || []).forEach(paramId => {
        const parameter = block.parameters[paramId];

        if (sectionKey === 'filter_category') {
          if (level === parameter.level) filterParameterId = paramId;
        } else if (sectionKey === 'filter_application') {
          if (resource === parameter.resource) filterParameterId = paramId;
        } else if (sectionKey === 'filter_analysis') {
          if (referenceId === parameter.reference_id) filterParameterId = paramId;
        } else if (sectionKey === 'filter_custom') {
          if (
            filterCustomReferenceId === parameter.filter_custom_reference_id &&
            referenceId === parameter.reference_id
          )
            filterParameterId = paramId;
        }
      });
    });

    return filterParameterId;
  };

  getFilterParameterById = (filter, sectionKey, id) => {
    let parameter;
    Object.values(filter[sectionKey]).forEach(block => {
      if (block.parameters.hasOwnProperty(id)) parameter = block.parameters[id];
    });
    return parameter;
  };

  removeFilterParameter = ({
    sectionKey,
    resource,
    level,
    referenceId,
    filterCustomReferenceId,
  }) => {
    // if (goOnly) {
    //   const updatedFilter = filterUtils.removeFilterParameter({
    //     sectionKey,
    //     resource,
    //     level,
    //     referenceId,
    //     filterCustomReferenceId,
    //     filterGo,
    //   });

    //   return this.props.dispatch(actions.updateFilterGo(updatedFilter));
    // }

    const filterObj = cloneDeep(this.props.filter);

    const id = this.getFilterParameterId({
      sectionKey,
      resource,
      level,
      referenceId,
      filterCustomReferenceId,
    });
    Object.values(filterObj[sectionKey]).forEach(block => {
      if (block.parameters.hasOwnProperty(id)) delete block.parameters[id];
    });
    this.props.dispatch(actions.setFilter(filterObj));
    if (sectionKey === 'filter_category') {
      this.props.dispatch(actions.deleteFilterCategoryParameter(id));
    } else if (sectionKey === 'filter_application') {
      this.props.dispatch(actions.deleteFilterApplicationParameter(id));
    } else if (sectionKey === 'filter_analysis') {
      this.props.dispatch(actions.deleteFilterAnalysisParameter(id));
    } else if (sectionKey === 'filter_custom') {
      this.props.dispatch(actions.deleteFilterCustomParameter(id));
    }
  };

  removeFilterValues = (sectionKey, parameterKey, ids) => {
    const filterObj = cloneDeep(this.props.filter);
    const parameter = this.getFilterParameterById(filterObj, sectionKey, parameterKey);
    Object.keys(parameter.values).forEach(valueId => {
      if (ids.includes(valueId)) delete parameter.values[valueId];
    });

    return this.props.dispatch(actions.setFilter(filterObj));
  };

  removeFilterParameterValues = ({
    sectionKey,
    resource,
    level,
    referenceId,
    filterCustomReferenceId,
    values,
    changeValue,
  }) => {
    const { filter } = this.props;

    // if (goOnly) {
    //   const updatedFilter = filterUtils.removeFilterParameterValues({
    //     sectionKey,
    //     resource,
    //     level,
    //     referenceId,
    //     filterCustomReferenceId,
    //     values,
    //     filterGo,
    //   });

    //   return changeValue
    //     ? this.props.dispatch(actions.setFilterGo(updatedFilter))
    //     : this.props.dispatch(actions.updateFilterGo(updatedFilter));
    // }

    const parameterId = this.getFilterParameterId({
      sectionKey,
      resource,
    });
    const parameter = this.getFilterParameterById(filter, sectionKey, parameterId);
    const valueIds = values.map(v => this.getValueId(parameter, v));

    if (sectionKey === 'filter_application') {
      this.props.dispatch(actions.removeFilterApplicationParameterValues(valueIds));
    }
    return this.removeFilterValues(sectionKey, parameterId, valueIds);
  };

  removeFilterParameterValue = ({
    sectionKey,
    resource,
    level,
    referenceId,
    filterCustomReferenceId,
    value,
  }) => {
    const { filter } = this.props;
    const { goOnly } = this.state;

    if (goOnly)
      return this.removeFilterParameterValues({
        sectionKey,
        resource,
        level,
        referenceId,
        filterCustomReferenceId,
        values: [value],
      });

    const parameterId = this.getFilterParameterId({
      sectionKey,
      resource,
      level,
      referenceId,
      filterCustomReferenceId,
    });
    const parameter = this.getFilterParameterById(filter, sectionKey, parameterId);
    const valueId = this.getValueId(parameter, value);

    // if last value and option "is", "is not", "with any value, excluding" delete parameter
    if ([1, 2, 4].includes(parameter.option_id) && Object.keys(parameter.values).length === 1) {
      this.removeFilterParameter({
        sectionKey,
        resource,
        level,
        referenceId,
        filterCustomReferenceId,
      }); // update filter
    } else if (value) {
      this.removeFilterValues(sectionKey, parameterId, [valueId]);
      if (sectionKey === 'filter_category') {
        this.props.dispatch(actions.removeFilterCategoryParameterValue(valueId));
      } else if (sectionKey === 'filter_application') {
        this.props.dispatch(actions.removeFilterApplicationParameterValues(valueId));
      } else if (sectionKey === 'filter_analysis') {
        this.props.dispatch(actions.removeFilterAnalysisParameterValues(valueId));
      } else if (sectionKey === 'filter_custom') {
        this.props.dispatch(actions.removeFilterCustomParameterValues(parameterId, valueId));
      }
    }
  };

  removeFilterSection = sectionKey => {
    // const { filterGo } = this.props;
    // const { goOnly } = this.state;

    // if (goOnly) {
    //   const updatedFilter = filterUtils.removeFilterSection(sectionKey, filterGo);
    //   return this.props.dispatch(actions.updateFilterGo(updatedFilter));
    // }

    const sectionFilterId = this.getSectionFilterId(sectionKey);
    if (sectionKey === 'filter_category') {
      this.props.dispatch(actions.deleteCategoryFilter(sectionFilterId));
    } else if (sectionKey === 'filter_application') {
      this.props.dispatch(actions.deleteApplicationFilter(sectionFilterId));
    } else if (sectionKey === 'filter_analysis') {
      this.props.dispatch(actions.deleteAnalysisFilter(sectionFilterId));
    } else if (sectionKey === 'filter_custom') {
      this.props.dispatch(actions.deleteCustomFilter(sectionFilterId));
    }
  };

  addFilterParameterValue = ({
    sectionKey,
    resource,
    level,
    referenceId,
    filterCustomReferenceId,
    value,
    resourceId,
    resourceName,
    optionId,
  }) => {
    // const { filterGo } = this.props;
    // const { goOnly } = this.state;

    // if (goOnly) {
    //   const updatedFilter = filterUtils.addFilterParameterValue({
    //     sectionKey,
    //     resource,
    //     level,
    //     referenceId,
    //     filterCustomReferenceId,
    //     value,
    //     resourceId,
    //     resourceName,
    //     optionId,
    //     filterGo,
    //   });

    //   return this.props.dispatch(actions.updateFilterGo(updatedFilter));
    // }

    const filterObj = cloneDeep(this.props.filter);
    const sectionFilter = this.getSectionFilter(filterObj, sectionKey);
    const parameterId = this.getFilterParameterId({
      sectionKey,
      resource,
      level,
      referenceId,
      filterCustomReferenceId,
    });
    let parameter = this.getFilterParameterById(filterObj, sectionKey, parameterId);

    if (sectionKey === 'filter_category') {
      if (parameterId) {
        parameter.values.temp = { cagtegory_id: resourceId, category_name: resourceName };
        this.props.dispatch(actions.addFilterCategoryParameterValue(parameterId, resourceId));
      } else {
        const categoryFilterId = Object.keys(this.props.filter.filter_category)[0];
        this.props.dispatch(
          actions.createFilterCategoryParameter(categoryFilterId, optionId || 1, level, resourceId)
        );
        sectionFilter.parameters.temp = {
          option_id: 1,
          level: resourceName,
          values: { temp: { category_id: resourceId, category_name: resourceName } },
        };
      }
    } else if (sectionKey === 'filter_application') {
      // add value temporary to the parameter
      const name = resourceName;
      if (!parameterId) {
        parameter = { option_id: 1, resource, values: {} };
        sectionFilter.parameters.temp = parameter;
      }

      if (resource === 'years' && resourceId instanceof Array) {
        resourceId.forEach((id, index) => {
          parameter.values[`temp${index}`] = { resource_id: id, resource_name: id };
        });
      } else {
        parameter.values.temp = { resource_id: resourceId, resource_name: name };
      }

      if (parameterId) {
        this.props.dispatch(actions.addFilterApplicationParameterValue(parameterId, resourceId));
      } else {
        const appFilterId = Object.keys(this.props.filter.filter_application)[0];
        this.props.dispatch(
          actions.createFilterApplicationParameter(appFilterId, 1, resource, resourceId)
        );
      }
    } else if (sectionKey === 'filter_analysis') {
      if (parameterId) {
        parameter.values.temp = { analysis_id: resourceId, analysis_name: resourceName };
        this.props.dispatch(actions.addFilterAnalysisParameterValue(parameterId, resourceId));
      } else {
        const analysisFilterId = Object.keys(this.props.filter.filter_analysis)[0];

        this.props.dispatch(
          actions.createFilterAnalysisParameter(analysisFilterId, 1, referenceId, resourceId)
        );
        sectionFilter.parameters.temp = {
          option_id: 1,
          reference_id: referenceId,
          values: { temp: { analysis_id: resourceId, analysis_name: resourceName } },
        };
      }
    } else if (sectionKey === 'filter_custom') {
      if (parameterId) {
        return this.props.dispatch(actions.addFilterCustomParameterValue(parameterId, value));
      }
      const customFilterId = Object.keys(this.props.filter.filter_custom)[0];

      return this.props.dispatch(
        actions.createFilterCustomParameter({
          filterId: customFilterId,
          customRefId: filterCustomReferenceId,
          optionId,
          value,
          refId: referenceId,
        })
      );
    }
    this.props.dispatch(actions.setFilter({ ...filterObj }));
  };

  updateFilterOption = params => {
    // const { filterGo } = this.props;
    // const { goOnly } = this.state;

    // if (goOnly) {
    //   const updatedFilter = filterUtils.updateFilterOption({ ...params, filterGo });
    //   return this.props.dispatch(actions.updateFilterGo(updatedFilter));
    // }

    const parameterId = this.getFilterParameterId({
      sectionKey: params.sectionKey,
      resource: params.resource,
      level: params.level,
      referenceId: params.referenceId,
      filterCustomReferenceId: params.filterCustomReferenceId,
    });

    if (params.sectionKey === 'filter_category') {
      this.props.dispatch(actions.updateCategoryFilterParameter(parameterId, params.optionId));
    }
    if (params.sectionKey === 'filter_application') {
      this.props.dispatch(actions.updateApplicationFilterParameter(parameterId, params.optionId));
    }
    if (params.sectionKey === 'filter_analysis') {
      this.props.dispatch(actions.updateAnalysisFilterParameter(parameterId, params.optionId));
    }
    if (params.sectionKey === 'filter_custom') {
      this.props.dispatch(actions.updateCustomFilterParameter(parameterId, params.optionId));
    }
  };

  menuLinks = () => {
    const { user } = this.props;
    const receiverUser = isReceiver(user);
    const linkData = filterNavigationBarElements;

    return linkData.map((link, index) => {
      // temporarily hide analysis filter for receiver
      if (link === 'Analysis' && receiverUser) return null;
      return (
        <span
          key={link}
          className={`box box_background filter__tab ${this.isActive(index)}`}
          onClick={() => this.handleFilterTabs(index)}
        >
          {link}
        </span>
      );
    });
  };

  editingArea = () => {
    const { activeFilterTab } = this.state;
    if (activeFilterTab === 0) {
      return (
        <PartTypeFilterContainer
          filter={this.props.filterGo}
          filterOld={this.props.filter}
          addFilterParameterValue={this.addFilterParameterValue}
          updateFilterOption={this.updateFilterOption}
        />
      );
    }
    if (activeFilterTab === 1) {
      return (
        <ApplicationFilterContainer
          filter={this.props.filterGo}
          filterOld={this.props.filter}
          removeFilterParameter={this.removeFilterParameter}
          removeFilterParameterValue={this.removeFilterParameterValue}
          addFilterParameterValue={this.addFilterParameterValue}
          removeFilterParameterValues={this.removeFilterParameterValues}
        />
      );
    }
    if (activeFilterTab === 2) {
      return (
        <AnalysisFilterContainer
          filter={this.props.filterGo}
          filterOld={this.props.filter}
          removeFilterParameterValue={this.removeFilterParameterValue}
          addFilterParameterValue={this.addFilterParameterValue}
        />
      );
    }
    if (activeFilterTab === 3) {
      return (
        <CustomFilterContainer
          filter={this.props.filterGo}
          filterOld={this.props.filter}
          addFilterParameterValue={this.addFilterParameterValue}
          removeFilterParameter={this.removeFilterParameter}
          removeFilterParameterValue={this.removeFilterParameterValue}
          updateFilterOption={this.updateFilterOption}
        />
      );
    }
  };

  render() {
    return (
      <div className="filter-drawer">
        <FilterBar
          filter={this.props.filterGo}
          removeFilterParameterValue={this.removeFilterParameterValue}
          updateFilterOption={this.updateFilterOption}
          removeFilterSection={this.removeFilterSection}
          removeFilterParameter={this.removeFilterParameter}
          getCustomFieldName={this.getCustomFieldName}
          getExpiName={this.getExpiName}
          getExpiValueName={this.getExpiValueName}
          getSubBrandName={this.getSubBrandName}
          parameterIsBool={this.parameterIsBool}
          populationStatuses={this.props.resources.item.population_statuses}
          brandItemTags={this.props.brandItemTags}
          availableChannels={this.props.availableChannels}
          availableReceivers={this.props.availableReceivers}
          readOnlyFilter={this.props.readOnlyFilter}
        />
        <div className="filter-container">
          <div className="filter__navigation">
            <span className="submenu-container">
              <span>{constants.filter.filter_by}</span>
              <ul className="submenu">{this.menuLinks()}</ul>
            </span>
          </div>
          <div className="filter__editing-container flex">
            <div className={`filter__editing ${this.props.readOnlyFilter ? 'disabled' : ''}`}>
              {this.editingArea()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FilterContainer.defaultProps = {
  filters: [],
  filter: {},
};

function mapStateToProps(state) {
  return {
    filter: state.catalogue.filter.filter,
    filterGo: state.catalogue.filter.filterGo,
    filters: getFiltersWithUser(state),
    brandItemTags: state.catalogue.catalogue.brandItemTags,
    categories: state.items.item.categories,
    subCategories: state.items.item.subCategories,
    partTypes: state.items.item.partTypes,
    subconfigs: state.resources.applicationVehicle,
    resources: state.resources.data,
    brandCustomFields: state.brand.customFields.customFields,
    user: state.user.user,
    availableChannels: state.brand.currentBrand.availableChannels,
    availableReceivers: state.brand.currentBrand.availableReceivers,
    subBrands: state.brand.currentBrand.subBrands,
    receiverSubBrands: state.receiverDataStream.subBrands,
  };
}

export { FilterContainer };
export default connect(mapStateToProps)(withContainerWrapper(FilterContainer));
