import React from 'react';
import classNames from 'classnames';
import { Select, Tooltip } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import { FilterType, FilterTypeGo } from '../../../../types/filter';
import {
  isAllFilter,
  isCustomFilter,
  isCustomPublicFilter,
  isFilterEmpty,
  isMarkedFilter,
  isTempFilter,
  mapFilterName,
} from '../../../utils/FilterUtils';
import catalogueTranslations from '../../../constants/CatalogueTranslations.json';

const { Option, OptGroup } = Select;

type CatalogueFilterSelectProps = {
  filters: FilterType[];
  selectedFilterGo: FilterTypeGo;
  selectedFilterId: number;
  onFilterChange: (params: { filterId: number }) => void;
};

const CatalogueFilterSelect = (props: CatalogueFilterSelectProps) => {
  const { filters, selectedFilterGo, selectedFilterId } = props;

  const tempFilter = filters.find(isTempFilter);
  const allFilter = filters.find(isAllFilter);
  const markedFilter = filters.find(isMarkedFilter);
  const customFilters = filters.filter(isCustomFilter);
  const customPublicFilter = filters.filter(isCustomPublicFilter);

  return (
    <Select
      value={selectedFilterId}
      onChange={(id: number) => props.onFilterChange({ filterId: id })}
      size="small"
      style={{ width: 200 }}
      className={classNames('catalogue-list-bar__filter-select', {
        'custom-filter': allFilter && selectedFilterId !== allFilter.id,
      })}
    >
      {tempFilter && (tempFilter.item_ids || !isFilterEmpty(selectedFilterGo)) && (
        <Option className="catalogue-list-bar__filter-temp" value={tempFilter.id}>
          <span className="catalogue-list-bar__filter-temp">{mapFilterName(tempFilter)}</span>
        </Option>
      )}
      {allFilter && <Option value={allFilter.id}>{mapFilterName(allFilter)}</Option>}
      {markedFilter && <Option value={markedFilter.id}>{mapFilterName(markedFilter)}</Option>}
      <OptGroup label={catalogueTranslations.myFilters}>
        {customFilters.map(filter => (
          <Option key={filter.id} value={filter.id}>
            <span className="ml-1"> {`${filter.name}`}</span>
          </Option>
        ))}
      </OptGroup>
      {customPublicFilter.map(filter => (
        <Option value={filter.id} key={filter.id}>
          <Tooltip title={filter.userEmail}>
            <GlobalOutlined className="mr-1" />
          </Tooltip>
          {`${filter.name}`}
        </Option>
      ))}
    </Select>
  );
};

export default CatalogueFilterSelect;
