import { connect } from 'react-redux';
import React from 'react';
import { message, Modal } from 'antd';
import queryString from 'query-string';
import { withTranslation, WithTranslation } from 'react-i18next';

import { withRouter, WithRouterProps } from '../withRouter';
import { getSelectedItems } from '../../selectors/catalogue/catalogueSelector';
import { syncItems } from '../../actions/brand/synchronisation';
import CatalogueList from '../../components/body/catalogue/CatalogueList';
import catalogueActions from '../../actions/catalogue/catalogue';
import { isReceiver, isManufacturer } from '../../utils/UserUtils';
import { FilterType, FilterTypeGo } from '../../../types/filter';
import { UserType } from '../../../types/user';
import { ApplicationState } from '../../reducers';
import { Item } from '../../../types/item';
import { ExportParamsState } from '../../components/body/catalogue/CatalogueExportModal';
import { AvailableChannel } from '../../../types/channel';
import { AvailableReceiver } from '../../../types/receiver';
import { CatalogueQueryParams } from '../../../types/catalogue';
import { BrandItemTag } from '../../../types/brand_catalogue';
import { startExport } from '../../actions/exporter';
import { fetchExtendedItemByPartNr } from '../../actions/items/item/fetch';
import { runScripts } from '../../actions/distribution_apisettings';
import { fetchBrandItemTags, fetchReceiverItemTags } from '../../actions/catalogue/catalogue/fetch';
import { getFilterTags } from '../../utils/FilterUtils';
import { getFiltersWithUser } from '../../selectors/catalogue/filterSelector';

type CatalogueListContainerProps = {
  dispatch: any;
  selectedFilterGo?: FilterTypeGo;
  allItemsSelected: boolean;
  brandId: number;
  receiverId?: number;
  selectedItemIds: number[];
  user?: UserType;
  brandItemTags: any[];
  itemsFetching: boolean;
  items: Item[];
  selectedItems: Item[];
  itemsSummary: number;
  brandItemsSummary: number;
  isFetchingItems: boolean;
  filters: FilterType[];
  allAccessableBrands: { [key: string]: any }[];
  isManufacturer?: boolean;
  isReceiver?: boolean;
  reverseSelected: boolean;
  reversedItemIds: number[];
  availableChannels: AvailableChannel[];
  availableReceivers: AvailableReceiver[];
  filterBrandIds: CatalogueQueryParams['filterBrandIds'];
  pageFormDirty: boolean;
  changeCatalougeHeight: () => void;
  handleAddTagFilter: (value: string) => void;
  handleRemoveTagFilter: (tagId: number) => void;
  selectedFilterTagIds: number[];
  readOnlyFilter?: boolean;
} & WithTranslation;

type OwnProps = {
  catalogueExtended: boolean;
  catalougeIsResizing: boolean;
  handleItemListScroll: (index: string) => void;
  updateSearchResult: (params: {
    filterId: number;
    page?: number | undefined;
    filterBrandIds?: number[] | undefined;
  }) => void;
};

type Props = CatalogueListContainerProps & OwnProps & WithRouterProps;

class CatalogueListContainer extends React.Component<Props> {
  componentDidMount() {
    const { selectedItemIds } = this.props;
    if (selectedItemIds.length === 1) this.handleSelectItem(selectedItemIds[0]);
    const params = queryString.parse(location.search, { arrayFormat: 'comma', parseNumbers: true });
    const partNumbers = Array.isArray(params.partNumbers)
      ? params.partNumbers
      : [...(params.partNumbers ? [params.partNumbers] : [])];

    if (partNumbers.length === 1) this.handleSelectItem(undefined, partNumbers[0]?.toString());
    // temp filter multi item select is handled in catalogue container
  }

  createActionParameters = (requiredParams?: { [key: string]: any }) => {
    const {
      selectedFilterGo,
      allItemsSelected,
      selectedItemIds,
      reverseSelected,
      reversedItemIds,
      isReceiver,
      filterBrandIds,
    } = this.props;

    return {
      ...requiredParams,
      filterId:
        (allItemsSelected && selectedItemIds.length >= 100) || reverseSelected
          ? selectedFilterGo?.id
          : undefined,
      withoutItemIds: reverseSelected && reversedItemIds ? reversedItemIds : undefined,
      itemIds:
        (!allItemsSelected || (allItemsSelected && selectedItemIds.length < 100)) &&
        !reverseSelected
          ? selectedItemIds
          : undefined,
      filterBrandIds: isReceiver ? filterBrandIds : undefined,
    };
  };

  handleSyncItems = () => {
    const { brandId } = this.props;

    const actionParams = this.createActionParameters({ brandId });
    this.props.dispatch(syncItems(actionParams));
  };

  handleStartExport = (exportState: ExportParamsState) => {
    const { brandId } = this.props;
    const actionParams = this.createActionParameters({
      ...exportState,
      exportSchemaId: 1,
      brandId,
    });

    // @ts-ignore
    this.props.dispatch(startExport(actionParams));
  };

  handleDeleteItems = () => {
    const actionParams = this.createActionParameters();
    return this.props.dispatch(catalogueActions.deleteItems(actionParams));
  };

  handleRunScript = (scriptId: number) => {
    const { t } = this.props;
    const actionParams = this.createActionParameters();

    this.props.dispatch(runScripts({ ...actionParams, scriptId }));
    message.info(t('api:runningScript'));
  };

  handleMarkItem = (itemId: number, marked: boolean) => {
    if (!marked) this.props.dispatch(catalogueActions.createMarkItem(itemId));
    else this.props.dispatch(catalogueActions.deleteMarkItem(itemId));
  };

  handleMarkAllItems = (allMarked: boolean) => {
    const { selectedFilterGo } = this.props;
    if (!allMarked) this.props.dispatch(catalogueActions.createMarkAllItems(selectedFilterGo!.id));
    else
      this.props.dispatch(catalogueActions.deleteAllMarkItems({ filterId: selectedFilterGo!.id }));
  };

  handleCheckAllItems = () => {
    this.props.dispatch(catalogueActions.selectAllItems());
  };

  handleSelectItem = (itemId?: number, partNr?: string) => {
    const { t, pageFormDirty, brandId, isManufacturer, isReceiver, dispatch } = this.props;
    const urlParams = new URLSearchParams(location.search);
    const urlBrandId = Number(urlParams.get('brandId'));

    const selectItem = async () => {
      let id = itemId;
      if (partNr) {
        const result = await dispatch(fetchExtendedItemByPartNr(partNr, brandId || urlBrandId));
        id = result.value.data.id;
      }
      if (id) {
        if (isManufacturer) dispatch(catalogueActions.fetchAllItemInfos(id));
        if (isReceiver) dispatch(catalogueActions.fetchAllItemInfosReceiver(id));
      }

      this.handleItemRouteUpdate([id!]);
    };

    if (pageFormDirty) {
      Modal.confirm({
        title: t('common:closeWarningTitle'),
        onOk() {
          selectItem();
        },
      });
    } else {
      selectItem();
    }
  };

  handleMultiSelectItems = (itemIds: number[], selectedItemId: number) => {
    this.props.dispatch(catalogueActions.multiSelectItems(itemIds, selectedItemId));
    this.handleItemRouteUpdate(itemIds);
  };

  handleAddToSelectedItems = (itemId: number) => {
    const { selectedItemIds } = this.props;

    this.props.dispatch(catalogueActions.addToSelectedItems(itemId));

    const itemIds = selectedItemIds.includes(itemId)
      ? selectedItemIds.filter(id => id !== itemId)
      : [...selectedItemIds, itemId];

    this.handleItemRouteUpdate(itemIds as number[]);
  };

  handleItemRouteUpdate = (itemIds: number[]) => {
    const { navigate, location, items, selectedItems, isReceiver, isManufacturer } = this.props;

    const params = queryString.parse(location.search, { arrayFormat: 'comma' });

    if (isManufacturer) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { partNumbers, ...rest } = params;
      navigate(
        `?${queryString.stringify(
          {
            ...rest,
            ...(itemIds.length <= 100
              ? {
                  partNumbers: itemIds.map(
                    id =>
                      items.find(i => i.id === id)?.part_number ||
                      selectedItems.find(i => i.id === id)?.part_number
                  ),
                }
              : {}),
          },
          { arrayFormat: 'comma' }
        )}`
      );
    }
    if (isReceiver) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { brandId, partNumbers, ...rest } = params;

      const item =
        items.find(i => i.id === itemIds[0]) || selectedItems.find(i => i.id === itemIds[0]);
      navigate(
        `?${queryString.stringify(
          {
            ...rest,
            ...(itemIds.length === 1 ? { partNumbers: item?.part_number } : {}),
            ...(itemIds.length === 1 ? { brandId: item?.parent_owner_brand_id } : {}),
          },
          { arrayFormat: 'comma' }
        )}`
      );
    }
  };

  handleAddTag = (newTagName: string) => {
    const { brandId, isReceiver, isManufacturer, receiverId } = this.props;
    const actionParams = this.createActionParameters({
      brandId: isManufacturer ? brandId : null,
      receiverId: isReceiver ? receiverId : null,
      tagName: newTagName,
    });
    this.props.dispatch(catalogueActions.createTagForItems(actionParams));
  };

  handleRemoveTag = async (tagName: string) => {
    const { brandId, receiverId, brandItemTags, selectedFilterGo, isManufacturer, isReceiver } =
      this.props;
    const brandTag = brandItemTags.find(({ name }) => name === tagName);
    const tagId = brandTag.id || '';

    const actionParams = this.createActionParameters({
      brandId: isManufacturer ? brandId : null,
      receiverId: isReceiver ? receiverId : null,
      tagId,
    });
    await this.props.dispatch(catalogueActions.deleteTagForItems(actionParams));
    // Refreshing the tags is required because a tag gets deleted when it is removed from the last
    // item it was assigned to. Otherwise we will have doplicate ids for the same tag name in the
    // frontend cache when this tag is used again
    if (isManufacturer)
      this.props.dispatch(fetchBrandItemTags(brandId)).then((response: any) => {
        const tags = response.value.data;
        const tagIds = tags.map((t: BrandItemTag) => t.id);
        const filterTagIds = getFilterTags(selectedFilterGo!);
        const removedFilterTagId = filterTagIds.find(id => !tagIds.includes(id));

        if (removedFilterTagId) this.props.handleRemoveTagFilter(removedFilterTagId);
      });
    else if (isReceiver && typeof receiverId === 'number')
      this.props.dispatch(fetchReceiverItemTags(receiverId)).then((response: any) => {
        const tags = response.value.data;
        const tagIds = tags.map((t: BrandItemTag) => t.id);
        const filterTagIds = getFilterTags(selectedFilterGo!);
        const removedFilterTagId = filterTagIds.find(id => !tagIds.includes(id));
        if (removedFilterTagId) this.props.handleRemoveTagFilter(removedFilterTagId);
      });
  };

  fetchItemTags = () => {
    const { selectedFilterGo, brandId, isReceiver, receiverId } = this.props;
    if (isReceiver) {
      return this.props.dispatch(
        catalogueActions.fetchItemTags({ filterId: selectedFilterGo!.id, receiverId })
      );
    }
    return this.props.dispatch(
      catalogueActions.fetchItemTags({ filterId: selectedFilterGo!.id, brandId })
    );
  };

  render() {
    return (
      <CatalogueList
        {...this.props}
        fetchItemTags={this.fetchItemTags}
        handleMarkItem={this.handleMarkItem}
        handleMarkAllItems={this.handleMarkAllItems}
        handleSelectItem={this.handleSelectItem}
        handleAddToSelectedItems={this.handleAddToSelectedItems}
        handleMultiSelectItems={this.handleMultiSelectItems}
        handleCheckAllItems={this.handleCheckAllItems}
        handleAddTag={this.handleAddTag}
        handleAddTagFilter={this.props.handleAddTagFilter}
        handleRemoveTagFilter={this.props.handleRemoveTagFilter}
        handleRemoveTag={this.handleRemoveTag}
        handleStartExport={this.handleStartExport}
        handleSyncItems={this.handleSyncItems}
        handleDeleteItems={this.handleDeleteItems}
        handleRunScript={this.handleRunScript}
        fetchFilter={this.props.updateSearchResult}
      />
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectedItems = getSelectedItems(state);
  return {
    user: state.user.user,
    brandId: state.parent.brands.selectedBrandId,
    receiverId: (state.user.user && state.user.user.receiver_id) || undefined,
    itemsFetching: state.catalogue.catalogue.itemsFetching,
    brandItemTags: state.catalogue.catalogue.brandItemTags,
    items: state.catalogue.catalogue.items,
    selectedItems,
    allItemsSelected: state.catalogue.catalogue.allItemsSelected,
    reverseSelected: state.catalogue.catalogue.reverseSelected,
    reversedItemIds: state.catalogue.catalogue.reversedItemIds,
    selectedItemIds: state.catalogue.catalogue.selectedItemIds,
    itemsSummary: state.catalogue.catalogue.itemsSummary,
    brandItemsSummary: state.catalogue.catalogue.brandItemsSummary,
    selectedFilterGo: state.catalogue.filter.filterGo,
    isFetchingItems: state.catalogue.catalogue.itemsFetching,
    filters: getFiltersWithUser(state),
    allAccessableBrands: state.receiverDataStream.allAccessableBrands,
    authorizedBrands: state.receiverDataStream.authorizedBrands,
    isReceiver: state.user.user && isReceiver(state.user.user),
    isManufacturer: state.user.user && isManufacturer(state.user.user),
    availableChannels: state.brand.currentBrand.availableChannels,
    availableReceivers: state.brand.currentBrand.availableReceivers,
    filterBrandIds: state.catalogue.catalogue.catalogueQueryParams.filterBrandIds,
    activeArea: state.app.navigationBar.activeArea,
    pageFormDirty: state.catalogue.catalogue.pageFormDirty,
  };
};

export default withRouter(connect(mapStateToProps)(withTranslation()(CatalogueListContainer)));
