import { SyncOutlined } from '@ant-design/icons';
import { Spin, Button, Alert } from 'antd';
import { connect } from 'react-redux';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getTempProductsFilter } from '../../selectors/catalogue/filterSelector';
import { subConfigAnalyses } from '../../selectors/analyses/analysesSelector';
import { getSelectedBrandCode } from '../../selectors/brand/brandSelector';
import { withContainerWrapper } from '../ContainerWrapper';
import Analyses from '../../components/body/analyses/Analyses';
import catalougeActions from '../../actions/catalogue/catalogue/index';
import {
  resetFilter,
  updateFilterGo,
  updateTempFilterItems,
} from '../../actions/catalogue/filter/update';
import itemsAnalysesActions from '../../actions/items/analyses';
import { fetchValidatedAnalysisTypes } from '../../actions/settings/scorecards';
import * as utils from '../../utils/Utils';
import { intercomEvent } from '../../utils/IntercomUtils';
import { AsyncDispatch } from '../../../types/global';
import { ApplicationState } from '../../reducers';
import { BrandItemTag } from '../../../types/brand_catalogue';
import { Analysis, ConfirmedVehicleBase } from '../../../types/analyses';
import { Analysis as AnalysisResources } from '../../../types/resources';
import { Item } from '../../../types/item';
import ApplicationEditDrawer from '../all_applications_validation/ApplicationEditDrawer';
import {
  NEW_ENGINE_ANALYSIS_ID,
  PART_TYPE_ANALYSIS_IDS,
  NEW_YEAR_ANALYSIS_ID,
  NEW_TRANSMISSION_ANALYSIS_ID,
  ENGINE_BASE_ANALYSIS_ID,
} from '../../constants/InboxConstants';
import { UserType } from '../../../types/user';
import { hasPermission, runningOnGoOnlyApi } from '../../utils/Permissions';
import { WithRouterProps, withRouter } from '../withRouter';
import { convertToGoFilterStructure } from '../../utils/FilterUtils';

const PART_TYPE_ROUTE = '/brand/validation/part-type';
const NEW_YEAR_ROUTE = '/brand/validation/new-year';
const ENGINE_BASE_ROUTE = '/brand/validation/engine-base';
const SUB_CONFIG_ROUTE = '/brand/validation/sub-config';
const NEW_ENGINE_ROUTE = '/brand/validation/new-engine';
const NEW_TRANSMISSION_ROUTE = '/brand/validation/new-transmission';

type InboxContainerProps = {
  dispatch: AsyncDispatch;
  loading: boolean;
  partTypeAnalyses: Analysis[];
  newYearAnalyses: Analysis[];
  engineBaseAnalyses: Analysis[];
  newEngineAnalyses: Analysis[];
  newTransmissionAnalyses: Analysis[];
  subConfigAnalyses: Analysis[];
  confirmedVehicleBases: ConfirmedVehicleBase[];
  analysisResources: AnalysisResources;
  reviewCategories: any;
  fetchingReviewCategories: boolean;
  brandItemTags: BrandItemTag[];
  brandId: number;
  tempProductsFilter: any;
  routePathname: string;
  fetchingApplications: boolean;
  selectedApplicationId: number;
  fetchingApplicationItem: boolean;
  goOnly: boolean;
  applicationItem?: Item;
  validatedAnalysisTypeIds: number[];
  user: UserType;
  brandCode?: string;
} & WithRouterProps &
  WithTranslation;

class InboxContainer extends React.Component<InboxContainerProps> {
  scrollArea: any;

  componentDidMount() {
    this.fetchReviews();
    this.props.dispatch(fetchValidatedAnalysisTypes(this.props.brandId));
  }

  componentDidUpdate(prevProps: InboxContainerProps) {
    const { routePathname } = this.props;
    if (prevProps.brandId !== this.props.brandId) {
      this.fetchReviews();
    }
    if (routePathname !== prevProps.routePathname) {
      this.fetchReviews();
    }
  }

  handleAddTag = (itemIds: number[], tagName: string) => {
    const { brandId } = this.props;
    this.props.dispatch(catalougeActions.createTagForItems({ itemIds, tagName, brandId }));
  };

  handleRemoveTag = (itemIds: number[], tagName: string) => {
    const { brandId, brandItemTags } = this.props;
    const brandTag = brandItemTags.find(({ name }) => name === tagName);
    const tagId = brandTag?.id;
    this.props.dispatch(catalougeActions.deleteTagForItems({ itemIds, tagId, brandId }));
  };

  addItemMarks = (itemIds: number[]) => {
    this.props.dispatch(catalougeActions.createMarkItems(itemIds));
  };

  removeItemMarks = (itemIds: number[]) => {
    this.props.dispatch(catalougeActions.deleteAllMarkItems({ itemIds }));
  };

  sendIntercomEvents = (path: string) => {
    let location = '';
    if (path === PART_TYPE_ROUTE) location = 'part-type';
    if (path === NEW_YEAR_ROUTE) location = 'new-year';
    if (path === ENGINE_BASE_ROUTE) location = 'engine-base';
    if (path === SUB_CONFIG_ROUTE) location = 'sub-config';
    if (path === NEW_ENGINE_ROUTE) location = 'new-engine';
    if (path === NEW_TRANSMISSION_ROUTE) location = 'new-transmission';

    intercomEvent('viewed-quick-fixes', { location, brand_code: this.props.brandCode! });
  };

  fetchReviews = () => {
    this.sendIntercomEvents(this.props.routePathname);
    if (this.props.routePathname === PART_TYPE_ROUTE)
      this.props.dispatch(itemsAnalysesActions.fetchPartTypeAnalyses(this.props.brandId));
    else if (this.props.routePathname === NEW_YEAR_ROUTE)
      this.props.dispatch(itemsAnalysesActions.fetchNewYearAnalyses(this.props.brandId));
    else if (this.props.routePathname === ENGINE_BASE_ROUTE)
      this.props.dispatch(itemsAnalysesActions.fetchEngineBaseAnalyses(this.props.brandId));
    else if (this.props.routePathname === SUB_CONFIG_ROUTE)
      this.props.dispatch(itemsAnalysesActions.fetchSubConfigAnalyses(this.props.brandId));
    else if (this.props.routePathname === NEW_ENGINE_ROUTE)
      this.props.dispatch(itemsAnalysesActions.fetchNewEngineAnalyses(this.props.brandId));
    else if (this.props.routePathname === NEW_TRANSMISSION_ROUTE)
      this.props.dispatch(itemsAnalysesActions.fetchNewTransmissionAnalyses(this.props.brandId));
  };

  fetchNextAnalyses = (event: any) => {
    if (this.props.routePathname === PART_TYPE_ROUTE) {
      this.fetchNextPartTypeAnalyses(event);
    } else if (this.props.routePathname === NEW_YEAR_ROUTE) {
      this.fetchNextNewYearAnalyses(event);
    } else if (this.props.routePathname === ENGINE_BASE_ROUTE) {
      this.fetchNextEngineBaseAnalyses(event);
    } else if (this.props.routePathname === SUB_CONFIG_ROUTE) {
      this.fetchNextSubConfigAnalyses(event);
    } else if (this.props.routePathname === NEW_ENGINE_ROUTE) {
      this.fetchNextNewEngineAnalyses(event);
    } else if (this.props.routePathname === NEW_TRANSMISSION_ROUTE) {
      this.fetchNextNewEngineAnalyses(event);
    }
  };

  fetchNextPartTypeAnalyses = (event: any) => {
    const nextPage = utils.nextPage(event, 100, this.props.partTypeAnalyses.length);
    if (nextPage && !this.props.loading) {
      this.props.dispatch(itemsAnalysesActions.fetchPartTypeAnalyses(this.props.brandId, nextPage));
    }
  };

  fetchNextNewYearAnalyses = (event: any) => {
    const nextPage = utils.nextPage(event, 100, this.props.newYearAnalyses.length);
    if (nextPage && !this.props.loading) {
      this.props.dispatch(itemsAnalysesActions.fetchNewYearAnalyses(this.props.brandId, nextPage));
    }
  };

  fetchNextEngineBaseAnalyses = (event: any) => {
    const { brandId, engineBaseAnalyses } = this.props;
    const nextPage = utils.nextPage(event, 100, engineBaseAnalyses.length);
    if (nextPage && !this.props.loading) {
      this.props.dispatch(itemsAnalysesActions.fetchEngineBaseAnalyses(brandId, nextPage));
    }
  };

  fetchNextNewEngineAnalyses = (event: any) => {
    const { brandId, newEngineAnalyses } = this.props;
    const nextPage = utils.nextPage(event, 10, newEngineAnalyses.length);
    if (nextPage && !this.props.loading) {
      this.props.dispatch(itemsAnalysesActions.fetchNewEngineAnalyses(brandId, nextPage));
    }
  };

  fetchNextSubConfigAnalyses = (event: any) => {
    const { brandId, newYearAnalyses } = this.props;
    const nextPage = utils.nextPage(event, 100, newYearAnalyses.length);
    if (nextPage && !this.props.loading) {
      this.props.dispatch(itemsAnalysesActions.fetchSubConfigAnalyses(brandId, nextPage));
    }
  };

  fetchReviewItems = (groupKey: string) =>
    this.props.dispatch(itemsAnalysesActions.fetchAnalysesGroupItems(this.props.brandId, groupKey));

  fetchReviewCategories = (keywords?: string) =>
    this.props.dispatch(itemsAnalysesActions.fetchReviewCategories(this.props.brandId, keywords));

  updateReviewCategory = (
    groupKey: string,
    referenceId: number,
    categoryId: number,
    typeId: number
  ) =>
    this.props.dispatch(
      itemsAnalysesActions.updateReviewCategory(
        groupKey,
        referenceId,
        categoryId,
        typeId,
        this.props.brandId
      )
    );

  fetchNextReviewCategories = (event: any, keywords?: string) => {
    const page = utils.nextPage(event);
    if (page && !this.props.fetchingReviewCategories) {
      this.props.dispatch(
        itemsAnalysesActions.fetchReviewCategories(this.props.brandId, keywords, page)
      );
    }
  };

  confirmReviews = ({
    typeId,
    analysisIds,
    validValueId,
    vehicleBaseIds,
  }: {
    typeId: number;
    analysisIds: number[];
    validValueId?: number;
    vehicleBaseIds?: number[];
  }) => {
    const { brandId } = this.props;
    this.props.dispatch(
      itemsAnalysesActions.confirmReviews({
        itemAnalysisIds: analysisIds,
        typeId,
        brandId,
        validValueId,
        vehicleBaseIds,
      })
    );
  };

  // reopenReviews = (analysisIds: number[]) => {
  //   this.props.dispatch(itemsAnalysesActions.reopenReviews(analysisIds));
  // };

  declineReviews = (analysisIds: number[]) => {
    this.props.dispatch(itemsAnalysesActions.declineReviews(analysisIds));
  };

  // confirmAllReviews = (typeId: number, groupKey: string) => {
  //   this.props.dispatch(
  //     itemsAnalysesActions.confirmAllReviews(groupKey, typeId, this.props.brandId)
  //   );
  // };

  declineAllReviews = (typeId: number, groupKey: string) => {
    this.props.dispatch(
      itemsAnalysesActions.declineAllReviews(groupKey, typeId, this.props.brandId)
    );
  };

  addToTempFilter = async (itemIds: number[]) => {
    const { brandId, tempProductsFilter, goOnly } = this.props;
    // const filterResponse = await this.props.dispatch(resetFilter(tempProductsFilter.id));
    await this.props.dispatch(resetFilter(tempProductsFilter.id));

    if (goOnly) {
      // // updateTempFilterItems
      // await this.props.dispatch(
      //   updateFilterGo({
      //     ...filterResponse.value.data.filter,
      //     item_ids: itemIds.join(),
      //   })
      // );
    } else {
      await this.props.dispatch(updateTempFilterItems(tempProductsFilter.id, itemIds));
      // const updateItemsResponse = await this.props.dispatch(
      //   updateTempFilterItems(tempProductsFilter.id, itemIds)
      // );
      // this.props.dispatch(
      //   updateFilterGo(
      //     convertToGoFilterStructure({
      //       ...updateItemsResponse.value.data.filter,
      //       item_ids: itemIds.join(),
      //     })
      //   )
      // );
    }
    this.props.navigate(
      `/brand/products/item?brandId=${brandId}&filterId=${tempProductsFilter.id}`
    );
  };

  refresh = () => {
    this.scrollArea.lastChild.scrollTop = 0;
    return this.fetchReviews();
  };

  getActiveTabAnalyses = () => {
    switch (this.props.routePathname) {
      case PART_TYPE_ROUTE:
        return this.props.partTypeAnalyses;
      case NEW_YEAR_ROUTE:
        return this.props.newYearAnalyses;
      case ENGINE_BASE_ROUTE:
        return this.props.engineBaseAnalyses;
      case NEW_ENGINE_ROUTE:
        return this.props.newEngineAnalyses;
      case NEW_TRANSMISSION_ROUTE:
        return this.props.newTransmissionAnalyses;
      case SUB_CONFIG_ROUTE:
        return this.props.subConfigAnalyses;
      default:
        return [];
    }
  };

  noRecommendationFound = () => {
    const { t, validatedAnalysisTypeIds: typeIds, routePathname, brandId } = this.props;
    let scorecardDisabled = false;
    if (
      routePathname === PART_TYPE_ROUTE &&
      (!typeIds.includes(PART_TYPE_ANALYSIS_IDS[0]) || !typeIds.includes(PART_TYPE_ANALYSIS_IDS[1]))
    )
      scorecardDisabled = true;
    else if (routePathname === NEW_ENGINE_ROUTE && !typeIds.includes(NEW_ENGINE_ANALYSIS_ID))
      scorecardDisabled = true;
    else if (
      routePathname === NEW_TRANSMISSION_ROUTE &&
      !typeIds.includes(NEW_TRANSMISSION_ANALYSIS_ID)
    )
      scorecardDisabled = true;
    else if (routePathname === NEW_YEAR_ROUTE && !typeIds.includes(NEW_YEAR_ANALYSIS_ID))
      scorecardDisabled = true;
    else if (routePathname === ENGINE_BASE_ROUTE && !typeIds.includes(ENGINE_BASE_ANALYSIS_ID))
      scorecardDisabled = true;

    return (
      <div className="analyses">
        <Alert
          className="m-4"
          message={
            scorecardDisabled ? t('quickfix:activateScorecard') : t('quickfix:noRecommendation')
          }
          description={
            scorecardDisabled ? (
              <Link
                to={{
                  pathname: '/brand/settings/scorecards',
                  search: `?brandId=${brandId}`,
                }}
              >
                {`${t('quickfix:scorecardSettings')}`}
              </Link>
            ) : undefined
          }
          type="info"
          showIcon
        />
      </div>
    );
  };

  editingArea = () => {
    const { loading, user } = this.props;
    const analyses = this.getActiveTabAnalyses();

    if (analyses.length === 0 && !this.props.loading) {
      return this.noRecommendationFound();
    }
    const canManageQuickFixes = hasPermission(user, 'can_manage_quickfixes');

    return (
      <React.Fragment>
        {(!loading || (loading && analyses!.length > 0)) && (
          <Analyses
            canManageQuickFixes={canManageQuickFixes}
            analyses={analyses || []}
            analysesResources={this.props.analysisResources}
            brandItemTags={this.props.brandItemTags}
            confirmedVehicleBases={this.props.confirmedVehicleBases}
            fetchingReviewCategories={this.props.fetchingReviewCategories}
            fetchNext={this.fetchNextAnalyses}
            fetchReviewCategories={this.fetchReviewCategories}
            fetchNextReviewCategories={this.fetchNextReviewCategories}
            fetchReviewItems={this.fetchReviewItems}
            updateReviewCategory={this.updateReviewCategory}
            categories={this.props.reviewCategories}
            // confirmAllReviews={this.confirmAllReviews}
            declineAllReviews={this.declineAllReviews}
            confirmReviews={this.confirmReviews}
            // reopenReviews={this.reopenReviews}
            declineReviews={this.declineReviews}
            addToTempFilter={this.addToTempFilter}
            addItemMarks={this.addItemMarks}
            removeItemMarks={this.removeItemMarks}
            handleAddTag={this.handleAddTag}
            handleRemoveTag={this.handleRemoveTag}
          />
        )}
        <Spin spinning={this.props.loading} />
      </React.Fragment>
    );
  };

  render() {
    const {
      fetchingApplications,
      selectedApplicationId,
      fetchingApplicationItem,
      applicationItem,
      t,
    } = this.props;

    return (
      <div className="page-layout">
        <div className="page-layout__top-bar">
          <div className="page-layout__top-bar__container">
            <div className="page-layout__top-bar__actions">
              <Button
                type="primary"
                icon={<SyncOutlined />}
                loading={this.props.loading}
                onClick={this.refresh}
              >
                {t('quickfix:refresh')}
              </Button>
            </div>
          </div>
        </div>
        <div className="page-layout__content">
          <div className="inbox__editing" ref={node => (this.scrollArea = node)}>
            {this.editingArea()}
          </div>

          <ApplicationEditDrawer
            visible={!!selectedApplicationId}
            selectedApplicationId={
              !fetchingApplications && !fetchingApplicationItem ? selectedApplicationId : null
            }
            selectedItem={applicationItem || {}}
            hideDelete
            hideActionButtons
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    loading:
      state.brand.analyses.fetchingNewYearAnalyses ||
      state.brand.analyses.fetchingPartTypeAnalyses ||
      state.brand.analyses.fetchingEngineBaseAnalyses ||
      state.brand.analyses.fetchingNewEngineAnalyses ||
      state.brand.analyses.fetchingNewTransmissionAnalyses ||
      state.brand.analyses.fetchingSubConfigAnalyses,
    partTypeAnalyses: state.brand.analyses.partTypeAnalyses,
    newYearAnalyses: state.brand.analyses.newYearAnalyses,
    engineBaseAnalyses: state.brand.analyses.engineBaseAnalyses,
    newEngineAnalyses: state.brand.analyses.newEngineAnalyses,
    newTransmissionAnalyses: state.brand.analyses.newTransmissionAnalyses,
    confirmedVehicleBases: state.brand.analyses.confirmedVehicleBases,
    subConfigAnalyses: subConfigAnalyses(state),
    analysisResources: state.resources.analysis,
    reviewCategories: state.brand.analyses.reviewCategories,
    fetchingReviewCategories: state.brand.analyses.fetchingReviewCategories,
    brandItemTags: state.catalogue.catalogue.brandItemTags,
    brandId: state.parent.brands.selectedBrandId,
    tempProductsFilter: getTempProductsFilter(state),
    validatedAnalysisTypeIds: state.settings.scorecards.validatedAnalysisTypeIds,

    fetchingApplications: state.items.application.fetchingApplications,
    selectedApplicationId: state.items.application.selectedApplicationId,
    fetchingApplicationItem: state.allApplications.applicationList.fetchingApplicationItem,
    applicationItem: state.allApplications.applicationList.applicationItem,

    goOnly: state.user.user && runningOnGoOnlyApi(state.user.user),
    user: state.user.user,
    brandCode: getSelectedBrandCode(state),
  };
}

export { InboxContainer };
export default withRouter(
  connect(mapStateToProps)(withContainerWrapper(withTranslation()(InboxContainer)))
);
