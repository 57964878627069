import { cloneDeep, isEmpty } from 'lodash';

import {
  ALL_FILTER_TYPE_ID,
  CUSTOM_FILTER_TYPE_ID,
  MARKED_FILTER_TYPE_ID,
  TEMP_FILTER_TYPE_ID,
  filterOptions,
} from '../constants/FilterConstants';
import filterTranslations from '../constants/FilterTranslation.json';
import {
  FilterType,
  FilterTypeGo,
  FilterCustomParameterGo,
  FilterCategoryParameterValue,
  FilterApplicationParameterValue,
  FilterAnalysisParameterValue,
  FilterCustomParameterValue,
} from '../../types/filter';
import { Indexable } from '../../types/global';
import { BrandItemTag } from '../../types/brand_catalogue';

const filterParameterOptions: Indexable = filterOptions;

export const isTempFilter = ({ type_id: typeId }: FilterType | FilterTypeGo) =>
  typeId === TEMP_FILTER_TYPE_ID;
export const isAllFilter = ({ type_id: typeId }: FilterType | FilterTypeGo) =>
  typeId === ALL_FILTER_TYPE_ID;
export const isMarkedFilter = ({ type_id: typeId }: FilterType | FilterTypeGo) =>
  typeId === MARKED_FILTER_TYPE_ID;
export const isCustomFilter = ({ type_id: typeId, public: publicFilter }: FilterType | FilterTypeGo) =>
  typeId === CUSTOM_FILTER_TYPE_ID && publicFilter === 0;
export const isCustomPublicFilter = ({ type_id: typeId, public: publicFilter }: FilterType | FilterTypeGo) =>
  typeId === CUSTOM_FILTER_TYPE_ID && publicFilter === 1;

export const mapFilterName = (filter: FilterType | FilterTypeGo): string => {
  if (!filter || isEmpty(filter)) return '';
  switch (filter.type_id) {
    case ALL_FILTER_TYPE_ID:
      return filterTranslations.all_products;
    case MARKED_FILTER_TYPE_ID:
      return filterTranslations.marked_products;
    case TEMP_FILTER_TYPE_ID:
      return filterTranslations.temp_filter;
    default:
      return filter.name;
  }
};

export const isFilterEmpty = (filter: FilterTypeGo) => {
  const filterImpls = [
    filter.filter_analysis,
    filter.filter_application,
    filter.filter_category,
    filter.filter_custom,
  ].filter(Boolean);
  return (
    !filter.keywords &&
    filterImpls
      .map(f => f![0])
      .map(({ parameters }) => parameters)
      .every(params => !Object.keys(params).length)
  );
};

export function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined;
}

export const getFilterTags = (selectedFilter: FilterTypeGo) => {
  if (selectedFilter.filter_custom) {
    const customFilter = selectedFilter.filter_custom[0];

    let tagParameter: FilterCustomParameterGo | undefined;
    customFilter.parameters.forEach(parameter => {
      if (parameter.filter_custom_reference_id === 5) tagParameter = parameter;
    });
    return tagParameter ? tagParameter.values.map(val => Number(val.value)) : [];
  }
  return [];
};

export const getTableAndColumn = (filterCustomReferenceId: number) => {
  if (filterCustomReferenceId === 1)
    return {
      table_name: 'items',
      column: 'universal_part',
    };
  if (filterCustomReferenceId === 2)
    return {
      table_name: 'item_custom_fields',
      column: 'value',
      reference_column: 'brand_custom_field_id',
    };
  if (filterCustomReferenceId === 3)
    return {
      table_name: 'item_extended_informations',
      column: 'content',
      reference_column: 'type_id',
    };
  if (filterCustomReferenceId === 4)
    return {
      table_name: 'items',
      column: 'population_status_id',
      reference_column: 'type_id',
    };
  if (filterCustomReferenceId === 5)
    return {
      table_name: 'item_to_tags',
      column: 'tag_id',
    };
  if (filterCustomReferenceId === 6)
    return {
      table_name: 'item_delivery_statuses',
      column: 'id',
      reference_column: 'channel_id',
    };
  if (filterCustomReferenceId === 7)
    return {
      table_name: 'item_delivery_statuses',
      column: 'id',
      reference_column: 'receiver_id',
    };
  if (filterCustomReferenceId === 8)
    return {
      table_name: 'item_delivery_statuses',
      column: 'online',
      reference_column: 'channel_id',
    };
  if (filterCustomReferenceId === 9)
    return {
      table_name: 'item_delivery_statuses',
      column: 'online',
      reference_column: 'receiver_id',
    };
  if (filterCustomReferenceId === 10)
    return {
      table_name: 'items',
      column: 'parent_owner_brand_sub_brand_id',
    };
};

export const getFilterParameter = ({
  sectionKey,
  resource,
  level,
  referenceId,
  filterCustomReferenceId,
  filterGo,
}: {
  sectionKey: string;
  resource?: string;
  level?: number;
  referenceId?: number;
  filterCustomReferenceId?: number;
  filterGo: FilterTypeGo;
}) => {
  const filter = filterGo;
  let filterParameter;
  // @ts-ignore
  if (filter[sectionKey]) {
    // @ts-ignore
    filter[sectionKey][0].parameters.forEach((parameter: any) => {
      if (sectionKey === 'filter_category') {
        if (level === parameter.level) filterParameter = parameter;
      } else if (sectionKey === 'filter_application') {
        if (resource === parameter.resource) filterParameter = parameter;
      } else if (sectionKey === 'filter_analysis') {
        if (referenceId === parameter.reference_id) filterParameter = parameter;
      } else if (sectionKey === 'filter_custom') {
        if (
          filterCustomReferenceId === parameter.filter_custom_reference_id &&
          (referenceId || null) === parameter.reference_id
        )
          filterParameter = parameter;
      }
    });
  }

  return filterParameter;
};

export const removeFilterParameter = ({
  sectionKey,
  resource,
  level,
  referenceId,
  filterCustomReferenceId,
  filterGo,
}: {
  sectionKey: string;
  resource?: string;
  level?: number;
  referenceId?: number | null;
  filterCustomReferenceId?: number;
  filterGo: FilterTypeGo;
}) => {
  const filterObj = cloneDeep(filterGo);

  // @ts-ignore
  const updatedParameters = filterObj[sectionKey][0].parameters.filter((parameter: any) => {
    if (sectionKey === 'filter_category') {
      return level !== parameter.level;
    }
    if (sectionKey === 'filter_application') {
      return resource !== parameter.resource;
    }
    if (sectionKey === 'filter_analysis') {
      return referenceId !== parameter.reference_id;
    }
    if (sectionKey === 'filter_custom') {
      return !(
        filterCustomReferenceId === parameter.filter_custom_reference_id &&
        (referenceId || null) === parameter.reference_id
      );
    }
  });

  const updatedFilter = {
    ...filterObj,
    // @ts-ignore
    [sectionKey]: [{ ...filterObj[sectionKey][0], parameters: updatedParameters }],
  };

  return updatedFilter;
};

export const removeFilterParameterValues = ({
  sectionKey,
  resource,
  level,
  referenceId,
  filterCustomReferenceId,
  values,
  filterGo,
}: {
  sectionKey: string;
  resource?: string;
  level?: number;
  referenceId?: number;
  filterCustomReferenceId?: number;
  values: string[];
  filterGo: FilterTypeGo;
}) => {
  const filterObj = cloneDeep(filterGo);

  const parameter: any = getFilterParameter({
    sectionKey,
    resource,
    level,
    referenceId,
    filterCustomReferenceId,
    filterGo: filterObj,
  });

  // if last value and option "is", "is not", "with any value, excluding" delete parameter
  if (parameter?.values.length === values.length && [1, 2, 4].includes(parameter?.option_id)) {
    return removeFilterParameter({
      sectionKey,
      resource,
      level,
      referenceId,
      filterCustomReferenceId,
      filterGo,
    });
  }

  // @ts-ignore
  const updatedParameters = filterObj[sectionKey][0].parameters.map(parameter => {
    if (sectionKey === 'filter_category') {
      return level === parameter.level
        ? {
            ...parameter,
            values: parameter.values.filter(
              (v: FilterCategoryParameterValue) => !(values as string[]).includes(v.category_name)
            ),
          }
        : parameter;
    }
    if (sectionKey === 'filter_application') {
      return resource === parameter.resource
        ? {
            ...parameter,
            values: parameter.values.filter(
              (v: FilterApplicationParameterValue) =>
                !(values as string[]).includes(v.resource_name)
            ),
          }
        : parameter;
    }
    if (sectionKey === 'filter_analysis') {
      return referenceId === parameter.reference_id
        ? {
            ...parameter,
            values: parameter.values.filter(
              (v: FilterAnalysisParameterValue) => !(values as string[]).includes(v.analysis_name)
            ),
          }
        : parameter;
    }
    if (sectionKey === 'filter_custom') {
      return filterCustomReferenceId === parameter.filter_custom_reference_id &&
        referenceId === parameter.reference_id
        ? {
            ...parameter,
            values: parameter.values.filter(
              (v: FilterCustomParameterValue) => !(values as string[]).includes(v.value)
            ),
          }
        : parameter;
    }
  });

  const updatedFilter = {
    ...filterObj,
    // @ts-ignore
    [sectionKey]: [{ ...filterObj[sectionKey][0], parameters: updatedParameters }],
  };

  return updatedFilter;
};

export const removeFilterSection = (sectionKey: string, filterGo: FilterTypeGo) => {
  const filterObj = cloneDeep(filterGo);

  const updatedFilter = {
    ...filterObj,
    // @ts-ignore
    [sectionKey]: [{ ...filterObj[sectionKey][0], parameters: [] }],
  };

  return updatedFilter;
};

export const addFilterParameterValue = ({
  sectionKey,
  resource,
  level,
  referenceId,
  filterCustomReferenceId,
  value,
  resourceId,
  resourceName,
  optionId,
  filterGo,
}: {
  sectionKey: string;
  resource?: string;
  level?: number;
  referenceId?: number;
  filterCustomReferenceId?: number;
  value: string;
  resourceId: any;
  resourceName: string;
  optionId: number;
  filterGo: FilterTypeGo;
}) => {
  const filterObj = cloneDeep(filterGo);
  const parameterObj = getFilterParameter({
    sectionKey,
    resource,
    level,
    referenceId,
    filterCustomReferenceId,
    filterGo: filterObj,
  });

  // create section filter when required
  // @ts-ignore
  if (!filterObj[sectionKey]) filterObj[sectionKey] = [{ and_condition: true, parameters: [] }];

  let updatedParameters;

  if (parameterObj) {
    // @ts-ignore
    updatedParameters = filterObj[sectionKey][0].parameters.map((parameter: any) => {
      if (sectionKey === 'filter_category') {
        return level === parameter.level
          ? {
              ...parameter,
              values: [
                ...parameter.values,
                { category_id: resourceId, category_name: resourceName },
              ],
            }
          : parameter;
      }
      if (sectionKey === 'filter_application') {
        if (resource !== parameter.resource) return parameter;
        let updatedValues: FilterApplicationParameterValue[] = [];

        if (resource === 'years' && resourceId instanceof Array) {
          resourceId.forEach(id => updatedValues.push({ resource_id: id, resource_name: id }));
        } else {
          updatedValues = [
            ...parameter.values,
            { resource_id: resourceId, resource_name: resourceName },
          ];
        }
        return {
          ...parameter,
          values: updatedValues,
        };
      }
      if (sectionKey === 'filter_analysis') {
        return referenceId === parameter.reference_id
          ? {
              ...parameter,
              values: [
                ...parameter.values,
                { analysis_id: resourceId, analysis_name: resourceName },
              ],
            }
          : parameter;
      }
      if (sectionKey === 'filter_custom') {
        return filterCustomReferenceId === parameter.filter_custom_reference_id &&
          referenceId === parameter.reference_id
          ? { ...parameter, values: [...parameter.values, { value }] }
          : parameter;
      }
    });
  } else {
    if (sectionKey === 'filter_category') {
      updatedParameters = [
        ...filterObj[sectionKey]![0].parameters,
        {
          option_id: optionId || 1,
          option_name: filterParameterOptions[optionId || 1],
          level,
          values: [{ category_id: resourceId, category_name: resourceName }],
        },
      ];
    }
    if (sectionKey === 'filter_application') {
      const values = [];

      if (resource === 'years' && resourceId instanceof Array)
        resourceId.forEach(id => values.push({ resource_id: id, resource_name: id }));
      else values.push({ resource_id: resourceId, resource_name: resourceName });

      updatedParameters = [
        ...filterObj[sectionKey]![0].parameters,
        {
          option_id: optionId || 1,
          option_name: filterParameterOptions[optionId || 1],
          resource,
          values,
        },
      ];
    }
    if (sectionKey === 'filter_analysis') {
      updatedParameters = [
        ...filterObj[sectionKey]![0].parameters,
        {
          option_id: optionId || 1,
          option_name: filterParameterOptions[optionId || 1],
          reference_id: referenceId,
          values: [{ analysis_id: resourceId, analysis_name: resourceName }],
        },
      ];
    }
    if (sectionKey === 'filter_custom') {
      updatedParameters = [
        ...filterObj[sectionKey]![0].parameters,
        {
          option_id: optionId || 1,
          option_name: filterParameterOptions[optionId || 1],
          filter_custom_reference_id: filterCustomReferenceId,
          reference_id: referenceId,
          values: [...(value ? [{ value }] : [])],
          ...getTableAndColumn(filterCustomReferenceId!),
        },
      ];
    }
  }

  const updatedFilter = {
    ...filterObj,
    // @ts-ignore
    [sectionKey]: [{ ...filterObj[sectionKey][0], parameters: updatedParameters }],
  };

  return updatedFilter;
};

export const updateFilterOption = (params: {
  sectionKey: string;
  resource?: string;
  level?: number;
  referenceId?: number;
  filterCustomReferenceId?: number;
  optionId: number;
  filterGo: FilterTypeGo;
}) => {
  const { sectionKey, resource, level, referenceId, filterCustomReferenceId, optionId, filterGo } =
    params;
  const filterObj = cloneDeep(filterGo);
  const parameterObj = getFilterParameter({
    sectionKey,
    resource,
    level,
    referenceId,
    filterCustomReferenceId,
    filterGo: filterObj,
  });

  let updatedParameters;

  if (parameterObj) {
    // @ts-ignore
    updatedParameters = filterObj[sectionKey][0].parameters.map((parameter: any) => {
      if (sectionKey === 'filter_category') {
        return level === parameter.level
          ? {
              ...parameter,
              option_id: optionId,
              option_name: filterParameterOptions[optionId],
            }
          : parameter;
      }
      if (sectionKey === 'filter_application') {
        if (resource !== parameter.resource) return parameter;
        return {
          ...parameter,
          option_id: optionId,
          option_name: filterParameterOptions[optionId],
        };
      }
      if (sectionKey === 'filter_analysis') {
        return referenceId === parameter.reference_id
          ? {
              ...parameter,
              option_id: optionId,
              option_name: filterParameterOptions[optionId],
            }
          : parameter;
      }
      if (sectionKey === 'filter_custom') {
        return filterCustomReferenceId === parameter.filter_custom_reference_id &&
          (referenceId || null) === parameter.reference_id
          ? {
              ...parameter,
              option_id: optionId,
              option_name: filterParameterOptions[optionId],
            }
          : parameter;
      }
    });
  }

  const updatedFilter = {
    ...filterObj,
    // @ts-ignore
    [sectionKey]: [{ ...filterObj[sectionKey][0], parameters: updatedParameters }],
  };
  return updatedFilter;
};

export const addFilterTag = (
  customRefId: number,
  tag: BrandItemTag,
  optionId: number,
  filterGo: FilterTypeGo
) => {
  const filterObj = cloneDeep(filterGo);

  const parameterObj = getFilterParameter({
    sectionKey: 'filter_custom',
    filterCustomReferenceId: customRefId,
    filterGo: filterObj,
  });
  // create section filter when required
  if (!filterObj.filter_custom) filterObj.filter_custom = [{ and_condition: true, parameters: [] }];

  let updatedParameters;

  if (parameterObj) {
    updatedParameters = filterObj.filter_custom[0].parameters.map((parameter: any) => {
      return customRefId === parameter.filter_custom_reference_id
        ? { ...parameter, values: [...parameter.values, { value: tag.id }] }
        : parameter;
    });
  } else {
    updatedParameters = [
      ...filterObj.filter_custom[0].parameters,
      {
        option_id: optionId,
        option_name: filterParameterOptions[optionId],
        filter_custom_reference_id: customRefId,
        ...getTableAndColumn(customRefId),
        values: [{ value: tag.id }],
      },
    ];
  }

  const updatedFilter = {
    ...filterObj,
    filter_custom: [{ ...filterObj.filter_custom[0], parameters: updatedParameters }],
  };

  return updatedFilter;
};

export const removeFilterTag = (customRefId: number, tagId: number, filterGo: FilterTypeGo) => {
  const filterObj = cloneDeep(filterGo);

  const parameter: any = getFilterParameter({
    sectionKey: 'filter_custom',
    filterCustomReferenceId: customRefId,
    filterGo: filterObj,
  });

  let updatedParameters;

  // if last value delete parameter
  if (parameter?.values.length === 1) {
    updatedParameters = filterObj.filter_custom![0].parameters.filter(parameter => {
      return !(customRefId === parameter.filter_custom_reference_id);
    });
  } else {
    updatedParameters = filterObj.filter_custom![0].parameters.map(parameter => {
      return customRefId === parameter.filter_custom_reference_id
        ? { ...parameter, values: parameter.values.filter(v => tagId !== Number(v.value)) }
        : parameter;
    });
  }

  const updatedFilter = {
    ...filterObj,
    filter_custom: [{ ...filterObj.filter_custom![0], parameters: updatedParameters }],
  };

  return updatedFilter;
};

export const convertToGoFilterStructure = (filter: FilterType) => {
  const convertToArrayStructure = (filterConfig: any) => {
    const keys = Object.keys(filterConfig.parameters);

    const parameterArray: any[] = Object.values(filterConfig.parameters).map(
      (param: any, i: number) => ({
        ...param,
        ...(keys[i].includes('temp') && { temp: true }),
        values: Object.values(param.values),
      })
    );

    return { ...filterConfig, parameters: parameterArray };
  };

  const goFilter = {
    ...filter,
    ...(filter.filter_analysis && {
      filter_analysis: [convertToArrayStructure(Object.values(filter.filter_analysis)[0])],
    }),
    ...(filter.filter_application && {
      filter_application: [convertToArrayStructure(Object.values(filter.filter_application)[0])],
    }),
    ...(filter.filter_category && {
      filter_category: [convertToArrayStructure(Object.values(filter.filter_category)[0])],
    }),
    ...(filter.filter_custom && {
      filter_custom: [convertToArrayStructure(Object.values(filter.filter_custom)[0])],
    }),
  };

  return goFilter;
};
